import cookie from 'js-cookie'

export class ImageUpload {
  constructor(quill, options = {}) {
    // save the quill reference
    this.quill = quill
    // save options
    this.options = options
    // listen for drop and paste events
    this.quill
      .getModule('toolbar')
      .addHandler('image', this.selectLocalImage.bind(this))
  }

  /**
   * Select local image
   */
  selectLocalImage() {
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.click()

    // Listen upload local image and save to server
    input.onchange = () => {
      const file = input.files[0]

      // file type is only image.
      if (/^image\//.test(file.type)) {
        const checkBeforeSend =
          this.options.checkBeforeSend || this.checkBeforeSend.bind(this)
        checkBeforeSend(file, this.sendToServer.bind(this))
      } else {
        console.warn('You could only upload images.')
      }
    }
  }

  /**
   * Check file before sending to the server
   * @param {File} file
   * @param {Function} next
   */
  checkBeforeSend(file, next) {
    next(file)
  }

  /**
   * Send to server
   * @param {File} file
   */
  sendToServer(file) {
    // Handle custom upload

    const baseUrl = process.env.VUE_APP_API_URL
    const folderName = baseUrl.includes('api.airba.kz')
      ? 'f3-airba-marketplace-prod'
      : 'test-marketplace-f3'
    const url = `${baseUrl}/f3/api/v1/${folderName}/`,
      method = this.options.method || 'POST',
      name = this.options.name || 'image',
      headers = this.options.headers || {},
      callbackOK =
        this.options.callbackOK || this.uploadImageCallbackOK.bind(this),
      callbackKO =
        this.options.callbackKO || this.uploadImageCallbackKO.bind(this)

    if (url) {
      const fd = new FormData()

      fd.append(name, file)

      if (this.options.csrf) {
        // add CSRF
        fd.append(this.options.csrf.token, this.options.csrf.hash)
      }

      const xhr = new XMLHttpRequest()
      // init http query
      xhr.open(method, url, true)
      // add custom headers
      headers['Authorization'] = `Bearer ${cookie.get('accessToken')}`
      for (const index in headers) {
        xhr.setRequestHeader(index, headers[index])
      }

      // listen callback
      xhr.onload = () => {
        if (xhr.status === 201) {
          callbackOK(JSON.parse(xhr.responseText), this.insert.bind(this))
        } else {
          callbackKO({
            code: xhr.status,
            type: xhr.statusText,
            body: xhr.responseText,
          })
        }
      }

      if (this.options.withCredentials) {
        xhr.withCredentials = true
      }

      xhr.send(fd)
    } else {
      const reader = new FileReader()

      reader.onload = (event) => {
        callbackOK(event.target.result, this.insert.bind(this))
      }
      reader.readAsDataURL(file)
    }
  }

  /**
   * Insert the image into the document at the current cursor position
   * @param {String} dataUrl  The base64-encoded image URI
   */
  insert(dataUrl) {
    const index =
      (this.quill.getSelection() || {}).index || this.quill.getLength()
    this.quill.insertEmbed(index, 'image', dataUrl.url, 'user')
  }

  /**
   * callback on image upload succesfull
   * @param {Any} response http response
   */
  uploadImageCallbackOK(response, next) {
    next(response)
  }

  /**
   * callback on image upload failed
   * @param {Any} error http error
   */
  uploadImageCallbackKO(error) {
    alert(error)
  }
}
